import React, { Component,useEffect, useState, Fragment } from "react";
import Slide from "react-reveal";

class Resume extends Component {

  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;
    const skillmessage = this.props.data.skillmessage;
    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} 
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });

    const certifications = this.props.data.certifications.map(function (certification) {
      return (
          <div className="card" >
            <img
                className="card-img-top cert-pic"
                src={certification.badge}
                alt={certification.certification}
              />
            <div className="card-body">
              <p className="card-text">{certification.certification} </p>
            </div>
          </div>
      );
    });


    const skills = this.props.data.newskills.map((skills) => {
      const backgroundColor = this.getRandomColor();
      const className = "bar-expand " + skills.name.toLowerCase();
      const width = skills.level;
      const image = "/images/skills/"+skills.name+".png"
      return (
        <li key={skills.name}>          
            <span style={{ width, backgroundColor }} className={className}>          
          </span>
          <em>
          <div className="skill-logo-container">
            <img className="skill-logo" src={image} />
          </div>{skills.name}</em>
        </li>
      );
    });


    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row certifications">
            <div className="three columns header-col">
              <h1>
                <span>Certifications</span>
              </h1>
            </div> 
            <div className="nine columns main-col">{certifications}</div>
          </div>
        </Slide>

          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
      </section>
    );
  }
}

export default Resume;
