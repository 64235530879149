import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";
import Tabletop from "tabletop";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };

    ReactGA.initialize("G-ZYDQ946BX3");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  loadSkills(){
      Tabletop.init({
                key: "1gDWmWZN5WQlBY8nBm8oqiGOlTDSe41LQdWcEXObTyQo",
                simpleSheet: true
              })
              .then((data) => {

                let categories = Object.keys(data[0]).splice(2,Object.entries(data[0]).length);          
                
                let skills = data.map(element=>{

                    console.log(element)

                    let skill={
                      level: element.level,
                      name:element.name,
                      categorias: []
                    }

                    for (const [key, value] of Object.entries(element)) {
                      if(value=="1"){
                          skill.categorias.push(key);
                      }
                    }
                    return skill
                });
                
                let resume = this.state.resumeData.resume;
                resume.newskills = skills
                
                console.log(resume)
                this.setState({
                  resume: resume
                })

              })
      .catch((err) => console.warn(err));
  }


  componentDidMount() {
    this.getResumeData();
    this.loadSkills();
  }

  render() {


    return (
      <div className="App">
        <Header data={this.state.resumeData.main} />
        <About data={this.state.resumeData.main} />
        <Resume data={this.state.resume} />
        <Contact data={this.state.resumeData.main} />
        <Footer data={this.state.resumeData.main} />
      </div>
    );
  }
}

export default App;
