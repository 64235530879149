import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns main-col text-justify">
            <img
                className="profile-pic"
                src={profilepic}
                alt="Alan Reynoso"
              />
            </div>
            <div className="nine columns main-col ">
              <div className="row ">
              <div className="columns">
                <h2>About Me</h2>
                <p>{bio}</p>
              </div>
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address ">
                  <span>Contact me to on linked in for anything related with an employment of consultancy services</span>
                  <br />
                </p>
              </div>
              <div className="columns download">
                <p>
                  <a href={this.props.data.social[1].url} className="button">
                    <i className="fa fa-linkedin"></i> @alanreynosovega
                  </a>
                  <a href={this.props.data.social[0].url} className="button">
                    <i className="fa fa-twitter"></i> @alanreynosovega
                  </a>
                </p>
              </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
